import { useState, useRef, useEffect } from 'react';
import { audioGenreLinks } from '../settings/ThemeSetup';
import CharacterRoles from '../settings/CharacterRoles';

/**
 * Hook to manage the genre and associated functionalities.
 * 
 * @param {string} genre - Initial genre value, defaults to 'generic'.
 * @returns {Object} - Returns the current genre name, function to update genre class, and function to trigger audio.
 */
export function useGenre(genre = 'generic') {
    const [genreName, setGenreName] = useState(genre);

    const roleNames = CharacterRoles.getAllValues().map(role => role.name.toLowerCase());
    const audioTriggers = [...roleNames, 'submit'];

    const { triggerAudio } = useGenreAudio(genreName, audioTriggers);
    
    const updateGenreClass = newClass => setGenreName(newClass);

    return {
        genreName, 
        updateGenreClass,
        triggerAudio
    };
}

/**
 * Hook to manage audio based on the genre and button types.
 * 
 * @param {string} genreName - Current genre name.
 * @param {Array} buttonTypes - Types of buttons for which audio is to be triggered.
 * @returns {Object} - Returns a function to trigger audio for a specific button type.
 */
export function useGenreAudio(genreName, buttonTypes) {
    // useRef to hold audio instances for each voteType to avoid re-initialization.
    const audioRef = useRef({});

    useEffect(() => {
        for (const voteType of buttonTypes) {
            // Adjusted the reference to access buttonSounds
            const genreLinks = audioGenreLinks[genreName];
            if (genreLinks && genreLinks.buttonSounds && genreLinks.buttonSounds[voteType] && !audioRef.current[voteType]) {
                audioRef.current[voteType] = new Audio(genreLinks.buttonSounds[voteType]);
            }
        }
    }, [genreName, buttonTypes]);

    const triggerAudio = (voteType) => {
        // Adjusted the reference to check buttonSounds
        const genreLinks = audioGenreLinks[genreName];
        if (!genreLinks || !genreLinks.buttonSounds || !genreLinks.buttonSounds[voteType]) {
            console.error(`Invalid genre or voteType: ${genreName}, ${voteType}`);
            return;
        }

        if (audioRef.current[voteType]) {
            audioRef.current[voteType].currentTime = 0;
            audioRef.current[voteType].play();
        }
    };

    return {
        triggerAudio
    };
}
