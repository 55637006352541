import React, { useEffect } from 'react';
import Header from './Header';

import { Link, useLocation } from 'react-router-dom';

// Hook for fetching videos
import useFetchMovies from '../../hooks/useFetchMovies';
import endpoints from '../../settings/endpoints';

function App() {
    const query = "production";  // Query to fetch videos
    const [vids, loading, error] = useFetchMovies(query);
    const location = useLocation();

    const scrollToElementById = (id) => {
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'auto' });
    };
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('v');

        if (id) {
            scrollToElementById(id);
        }
    }, [location, loading]);


    // Handle loading state
    if (loading) return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );

    // Handle error state
    if (error) return <p>Error: {error.message}</p>;



    console.log(vids);

    return (
        <div className="App">
            <Header />
            <div className="App-content">
                <div className="main-container">
                    <div className="main-left">
                        <p>Static content on the left</p>
                    </div>
                    <div className="main-right">
                        {vids.map((vid) => <MainCard key={vid.sourceId} vid={vid} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}



function MainCard({ vid }) {
    return (
        <div id={vid.sourceId} className="main-card-block">
            <HeaderBlock vid={vid} />
            <VideoBlock vid={vid} />
        </div>
    );
}

function HeaderBlock({ vid }) {
    return (
        <div className='main-card-header-block'>
            <div className='main-card-header'>
                <p>Video:</p>
                {vid.title}
            </div>
            <div className='main-card-button'>
                <Link to={`/video/${vid.sourceId}`}>
                    <button id="main-vote-button">VOTE</button>
                </Link>
            </div>
        </div>
    );
}

function VideoBlock({ vid }) {
    const videoUrl = vid.sourceData.data.media.reddit_video.fallback_url;

    return (
        <div className='main-card-video-block'>
            <div className='main-card-video-player'>
                <Link to={`/video/${vid.sourceId}`}>
                    <video loop={true} controls >
                        <source src={videoUrl} type="video/mp4" />
                        Sorry, your browser does not support the video tag.
                    </video>
                </Link>
            </div>
            <ActorBlock vid={vid} />
        </div >
    );
}

function ActorBlock({ vid }) {
    const maxLength = 15;
    return (
        <div className='main-card-video-actors-column'>
            <div className='main-card-actor-block'>
                <div className='main-card-actor-banner'>Who's the Hero?</div>
            </div>

            {vid.actors.map((actor, actorKey) => {
                return (
                    <div key={actorKey} className='main-card-actor-block'>
                        <ActorThumbnail actor={actor} vid={vid} />
                        <ActorButtons actor={actor} vid={vid} />
                        <div className='main-card-actor-name'>{actor.name.slice(0, maxLength - 3) + '...'}</div>
                    </div>
                );
            })}
        </div>
    );
}

function ActorButtons({ vid }) {
    return (
        <Link to={`/video/${vid.sourceId}`}>
            <div className='main-card-actor-buttons'>
                <button className="round-button hero-color">
                    <i className="fas fa-thumbs-up"></i>
                </button>
                <button className="round-button victim-color">
                    <i className="fas fa-praying-hands"></i>
                </button>
                <button className="round-button villain-color">
                    <i className="fas fa-thumbs-down"></i>
                </button>
            </div>
        </Link>
    );
}

function ActorThumbnail({ actor, vid }) {
    return (
        <Link to={`/video/${vid.sourceId}`}>
            <div className='main-card-actor-image'>
                <img src={`${endpoints.thumbsRoot}${vid.sourceId}/${actor.thumb}`} title={actor.name} alt={actor.name} className="main-card-actor-thumbnail" />
            </div>
        </Link>
    );
}

export default App;
