import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import useFetchVideo from '../../hooks/useFetchVideo';
import useGetAudio from '../../hooks/useGetAudio';

import endpoints from '../../settings/endpoints';

import Person from './Person';

function AppX() {
 
    return ('Hello World!');

}


function App() {
    const { videoId } = useParams();
    const [video, loading, error] = useFetchVideo(videoId);
    const [playPause, setPlayPause] = useState();
    const [videoScrub, setVideoScrub] = useState(0);
    const vidRef = useRef(null);
    const audRef = useRef(null);

    const videoUrl = (loading) ? "" : video?.sourceData?.data?.media?.reddit_video?.fallback_url;
    const audioUrl = useGetAudio(videoUrl);
    console.log( "---->", video);

    const handlePlayback = (action, currentTime) => {
        if (action === 'Play') {
            audRef.current.currentTime = currentTime;
            audRef.current.play();
            vidRef.current.play();
            setPlayPause('Pause');
        } else if (action === 'Pause') {
            audRef.current.pause();
            setPlayPause('Play');
        } else if (action === 'Seek') {
            audRef.current.currentTime = currentTime;
            setVideoScrub(currentTime);
        }
    };

    // if (!video) return (
    //     <div className="loader-container">
    //         ERR!
    //     </div>
    // );

    if (loading) return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
    if (error) return <p>Error: {error.message}</p>;

    console.log(loading);
    if (video) {

    return (
        <div className="App">
            <div className="App-content">
                <div className="details-container">
                    <div className="details-left">
                        <div className="details-video-bar">
                            <Link to={`/home?v=${videoId}`} className="details-back-button">
                                <div className="details-close-video"> {closeVideoButton}</div>
                            </Link>
                        </div>
                        <VideoBlock video={video} videoId={videoId} videoUrl={videoUrl} audioUrl={audioUrl} handlePlayback={handlePlayback} vidRef={vidRef} audRef={audRef} />
                    </div>
                    <div className="details-right">
                        <Person video={video} videoId={videoId} handlePlayback={handlePlayback} people={video.actors} actorImgPrefix={`${endpoints.thumbsRoot}${videoId}`} videoTitle={video.title} videoUrl={videoUrl} vidRef={vidRef} />
                    </div>
                </div>
            </div>
        </div>
    );
    }else{
        return ('Hello World!');
    }
}


function VideoBlock({ video, videoId, videoUrl, audioUrl, handlePlayback, vidRef, audRef }) {
    // const videoUrl = video?.sourceData?.data?.media?.reddit_video?.fallback_url;

    console.log("videovideovideovideovideo", video);

    return (
        <div className='details-video-block'>
            <div className='details-video-player'>
                <video ref={vidRef} loop={false} autoPlay controls
                    onPlay={e => handlePlayback('Play', e.target.currentTime)}
                    onPause={e => handlePlayback('Pause', e.target.currentTime)}
                    onSeeking={e => handlePlayback('Seek', e.target.currentTime)}>
                    <source src={videoUrl} type="video/mp4" />
                    Sorry, your browser does not support the video tag.
                </video>
                <audio ref={audRef} id="audio" loop={false}>
                    {audioUrl.map(audioFileLink => <source key={audioFileLink} src={audioFileLink} type="audio/mp4" />)}
                </audio>
            </div>
        </div >
    );
}


const closeVideoButton = (
    <svg width="18" data-e2e="" height="18" viewBox="0 0 9 10" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.35299 0.792837L4.49961 3.93944L7.64545 0.792566C7.8407 0.597249 8.15733 0.597223 8.35262 0.792508L8.70669 1.14658C8.90195 1.34184 8.90195 1.65842 8.70669 1.85368L5.56027 5.0001L8.70672 8.14655C8.90198 8.34181 8.90198 8.65839 8.70672 8.85366L8.35316 9.20721C8.1579 9.40247 7.84132 9.40247 7.64606 9.20721L4.49961 6.06076L1.35319 9.20719C1.15793 9.40245 0.841345 9.40245 0.646083 9.20719L0.292629 8.85373C0.0973708 8.65847 0.0973653 8.3419 0.292617 8.14664L3.43895 5.0001L0.292432 1.85357C0.0972034 1.65834 0.0971656 1.34182 0.292347 1.14655L0.645801 0.792924C0.841049 0.597582 1.1577 0.597543 1.35299 0.792837Z"></path>
    </svg>
)

export default App;
