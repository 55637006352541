import React, { useEffect, useState } from 'react';
import endpoints from '../../settings/endpoints';
import { useAuth } from '../../hooks/useAuth';
import { useGenre } from '../../hooks/userGenre';
import useVotes from '../../hooks/useVotes';
import CharacterRoles from '../../settings/CharacterRoles';


// Component to display a person and handle voting.
function XPerson({ videoId,
    video,
    videoTitle,
    videoUrl,
    vidRef,
    handlePlayback,
    people,
    actorImgPrefix
}) {
    const { isLoggedIn, triggerLogin, LoginModal, showLoginModal } = useAuth();
    console.log("---->", people);

    return (
        <>
            <div className="details-right-content">
                <HeaderBlock videoId={videoId} videoTitle={videoTitle} videoUrl={videoUrl} handlePlayback={handlePlayback} vidRef={vidRef} />
                {people.map(actor => <ActorBlock actor={actor} vid={video} />)}
            </div>
            <div className="details-right-button">
                <button>
                    Vote
                </button>
            </div>
        </>
    );
}




// Component to display a person and handle voting.
function Person({ video, videoId, people, actorImgPrefix, videoUrl, vidRef, handlePlayback }) {
    const { isLoggedIn, triggerLogin, LoginModal, showLoginModal } = useAuth();
    const { genreName, triggerAudio } = useGenre();

    //State to keep track of the user's votes from the backend database
    const { retrieveVotes, userVotes, sendVotes, setUserVotes } = useVotes();
    // State to keep track of the current votes
    const [votes, setVotes] = useState({});
    // State to store the snapshot of the votes at the time of submission
    const [submittedVotes, setSubmittedVotes] = useState({});
    // State to track if the user has submitted at least once
    const [hasSubmitted, setHasSubmitted] = useState(false);  // New state

    // Handler to set votes when a vote button is clicked
    const handleVote = (personId, voteType, voteValue) => {
        const intVoteValue = parseInt(voteValue, 10);
        if (isNaN(intVoteValue)) {
            console.error("Invalid voteValue provided. Expected an integer.");
            return;
        }
        triggerAudio(voteType);
        setVotes(prevVotes => ({ ...prevVotes, [personId]: intVoteValue }));
    };

    useEffect(() => {
        retrieveVotes();
    }, []);

    useEffect(() => {
        if (!isLoggedIn) setUserVotes({});  // Clear user votes on logout
    }, [isLoggedIn]);

    useEffect(() => {
        // Update votes and submittedVotes based on the user's votes from the backend
        const currentVotes = userVotes?.userVotes?.moviesMap?.[videoId] || {};
        setVotes(currentVotes);
        setSubmittedVotes(currentVotes);
    }, [userVotes]);

    // Check if every person has received a vote
    const hasVotedForAll = () => people.every(person => votes[person.id]);

    // Check if current votes differ from the previously submitted votes
    const votesHaveChanged = () => !people.every(person => votes[person.id] === submittedVotes[person.id]);

    // Function to submit the votes to a backend endpoint
    const submitVotes = async () => {
        if (!isLoggedIn) {
            triggerLogin();
            return;
        }

        if (hasVotedForAll()) {
            try {
                sendVotes(votes, videoId);
                setSubmittedVotes({ ...votes });
                setHasSubmitted(true);
                triggerAudio('submit');
                console.log('Votes submitted successfully!');
            } catch (error) {
                console.error('Error submitting votes:', error);
            }
        }
    };



    return (
        <>
            {showLoginModal && <LoginModal />}
            <div className="details-right-content">
                <WhoTheBlock votes={votes} hasSubmitted={hasSubmitted} hasVotedForAll={hasVotedForAll} />
                <HeaderBlock videoId={videoId} videoTitle={video.title} videoUrl={videoUrl} handlePlayback={handlePlayback} vidRef={vidRef} />
                <div className="actorWrapper">
                    {people.map((actor, key) => <ActorBlock key={key} actor={actor} person={actor} vid={video} genreName={genreName} votes={votes} actorImgPrefix={actorImgPrefix} handleVote={handleVote} />)}
                </div>
            </div>
            <div className="details-right-button">
                <button disabled={!hasVotedForAll() || !votesHaveChanged()} onClick={submitVotes}>
                    {hasSubmitted ? `Change` : `Submit`} Vote
                </button>
            </div>
        </>
    );
}












function HeaderBlock({ videoId, videoTitle, videoUrl, handlePlayback, vidRef }) {
    return (
        <div className='details-header-block'>
            <div className='details-header'>
                <b>Video:</b>
                <p>{videoTitle}</p>
            </div>
        </div>
    );
}

function WhoTheBlock({ votes, hasVotedForAll }) {
    const roles = ['hero', 'victim', 'villain'];

    const randomIndex = Math.floor(Math.random() * roles.length);

    return (
        <h1 className='details-who-block'>
           {hasVotedForAll() ?  'VOTE!' : `Who's the ${roles[randomIndex]}?`}

            
        </h1>
    );
}


function ActorBlock({ actor, person, vid, genreName, votes, actorImgPrefix, handleVote }) {
    console.log(vid);

    return (
        <div key={person.id} className={`${genreName} actor ${votes[person.id] ? CharacterRoles.getRoleName(votes[person.id]).toLocaleLowerCase() : ''}`}>
            <img src={`${actorImgPrefix}/${person.thumb}`} alt={person.name} className="actor-details-thumbnail" />
            <h3 className='actor-details-name'>{person.name}</h3>
            <div className="votingButtons">
                {CharacterRoles.getAllValues().map(role => (
                    <button
                        key={role.name}
                        className={`${genreName} vote ${role.name.toLowerCase()} ${CharacterRoles.getRoleName(votes[person.id]).toLocaleLowerCase() === role.name.toLowerCase() ? 'selected' : ''}`}
                        onClick={() => handleVote(person.id, role.name.toLowerCase(), role.value)}>
                        {role.text}
                    </button>
                ))}
            </div>
        </div>


    );

    return (
        <div className='details-actor-block'>
            <div className='details-actor-image'>
                <img src={`${endpoints.thumbsRoot}${vid.sourceId}/${actor.thumb}`} title={actor.name} alt={actor.name} className="details-actor-thumbnail" />
            </div>
            <ActorButtons />
            <div className='details-actor-name'>{actor.name}</div>
        </div>
    );
}


function ActorButtons({ children }) {
    return (
        <div className='details-actor-buttons'>
            <button className="round-button hero-color">
                <i class="fas fa-thumbs-up"></i>
            </button>
            <button className="round-button victim-color">
                <i class="fas fa-praying-hands"></i>
            </button>
            <button className="round-button villain-color">
                <i class="fas fa-thumbs-down"></i>
            </button>
        </div>
    );
}



export default Person;