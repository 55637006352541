import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './useAuth';
import endpoints from '../settings/endpoints';

/**
 * Hook to manage user votes and associated operations like sending and retrieving votes.
 *
 * @returns {Object} Functions and states related to votes.
 */
const useVotes = () => {
    const { user, loginSuccess } = useAuth();
    const [userVotes, setUserVotes] = useState(null);
    const [votesLoading, setVotesLoading] = useState(false);
    const [error, setError] = useState(null);

    const token = user ? user.token : null;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    /**
     * Send votes to the endpoint.
     *
     * @param {Array} votes - Array of votes.
     * @param {string} movieId - ID of the movie being voted for.
     * @returns {Object|null} Response data or null in case of error.
     */
    const sendVotes = async (votes, movieId) => {
        setVotesLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${endpoints.baseUrl}${endpoints.endpoints.castUserVote}`, {
                votes,
                movieId,
                cognitoSub: user.sub,
                accessToken: user.accessToken
            }, config);

            setVotesLoading(false);
            return response.data; 
        } catch (err) {
            setVotesLoading(false);
            setError(err.message);
            return null; 
        }
    };

    /**
     * Retrieve user votes from the endpoint.
     *
     * @returns {Object|null} Response data or null in case of error.
     */
    const retrieveVotes = async () => {
        setVotesLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${endpoints.baseUrl}${endpoints.endpoints.getUserVotes}${user.sub}`);
            setVotesLoading(false);
            setUserVotes(response.data);
            return response.data;
        } catch (err) {
            setVotesLoading(false);
            setError(err.message);
            return null;
        }
    };

    // On successful login, retrieve votes.
    useEffect(() => {
        if (loginSuccess) retrieveVotes();
    }, [loginSuccess]);

    return {
        sendVotes,
        retrieveVotes,
        userVotes,
        setUserVotes,
        votesLoading,
        error
    };
};

export default useVotes;
