import { useState, useEffect } from 'react';
import axios from 'axios';
import endpoints from '../settings/endpoints';

/**
 * Custom hook to fetch a specific video based on a given sourceId.
 * 
 * @param {string} query - The sourceId to query the video by.
 * @returns {Array} - An array containing the fetched video, the loading state, and any error encountered.
 */
function useFetchVideo(query) {
    const [video, setVideo] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${endpoints.baseUrl}${endpoints.endpoints.getMovieVideo}`, {
                    params: { sourceId: query }  // Parameter to query the video by sourceId
                });

                setVideo(response.data);  //  API returns the video data directly.
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);  // Ensure loading is set to false even if there's an error
            }
        };

        fetchData();
    }, [query]);

    return [video, loading, error];
}

export default useFetchVideo;
