import React from 'react';
import { useAuth } from '../../hooks/useAuth';
function Header() {

  const { user, isLoggedIn, logout, triggerLogin, LoginModal, showLoginModal } = useAuth();


  return (
    
    <div className="header">
      {showLoginModal && <LoginModal />}
      <div className="logo">
        <b>
          <a href="/" className="hv3-logo">
            <span>H</span><span>V</span><span>3</span>
          </a>
        </b>
        <i>
          &nbsp;•&nbsp;
          <a href="/" className="hv3-logo">
            <span>Hero</span><span>Victim</span><span>Villian</span>
          </a>
          Vote
        </i>
      </div>

      <div className="header-feedback"></div>

      <div className="nav-links">
        {!isLoggedIn && (
          <button onClick={triggerLogin} id='login-button' className="nav-link">Log In</button>
        )}

        {isLoggedIn && (
          <>
            <span>{user.username}</span> &nbsp; 
            <button  onClick={logout} id='login-button' className="nav-link">Log Out</button>
          </>
          
        )}
      </div>

      
    </div>
  );
}

export default Header;
