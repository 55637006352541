import React, { useState, useEffect, useContext, createContext } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import endpoints from '../settings/endpoints';

// Create a Context for the auth state
const AuthContext = createContext();

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => { return useContext(AuthContext); }

function useProvideAuth() {
	const [user, setUser] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loginError, setLoginError] = useState(null);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [loginSuccess, setLoginSuccess] = useState(false);

	const login = async (username, password) => {
		setLoginError(null);
		
		try {
			const response = await axios.post(`${endpoints.baseUrl}${endpoints.endpoints.login}`, {
				username: username,
				password: password
			});
			const data = response.data;

			if (data && data.accessToken) {
				setAccessToken(data);
				setIsLoggedIn(true);
				return true;
			} else {
				setLoginError("Failed to login. Please check your credentials.");
				return false;
			}

		} catch (error) {
			setLoginError("Failed to login. Please check your credentials.");
			return false;
		}
	};

	const logout = async () => {
		try {
			const token = user ? user.accessToken : null;
			const config = {
				headers: {
					Authorization: `Bearer ${token}`
				}
			};
			await axios.post(`${endpoints.baseUrl}${endpoints.endpoints.logout}`, {}, config);

			setUser(null);
			setIsLoggedIn(false);
			localStorage.removeItem('user');

		} catch (error) {
			console.error('Error during logout:', error);
		}
	};

	const triggerLogin = () => {
		console.log('triggerLogin');
		if (!isLoggedIn) {
			setShowLoginModal(true);
		}
	};

	const setAccessToken = (data) => {
		const decodedToken = jwtDecode(data.accessToken);
		const idToken = jwtDecode(data.idToken);

		setUser({
			username: decodedToken.username,
			email: decodedToken.email,
			sub: decodedToken.sub,
			accessToken: data.accessToken,
			idToken: data.idToken,
			refreshToken: data.refreshToken
		});
	}

	const refreshAccessToken = async (refreshToken) => {
		if (!refreshToken) {
			logout();
			return null;
		}

		try {
			const response = await axios.post(`${endpoints.baseUrl}${endpoints.endpoints.refreshToken}`, {
				refreshToken: refreshToken
			});
			const data = response.data;

			if (data && data.accessToken) {
				setAccessToken(data);
			} else {
				logout();
			}
		} catch (error) {
			logout();
			console.error('Error during token refresh:', error);
		}
	};

	const isTokenExpired = (token) => {
		if (!token) return true;
		const decodedToken = jwtDecode(token);
		const currentTime = Math.floor(Date.now() / 1000);
		return decodedToken.exp < currentTime;
	};

	// useEffect to sync state with localStorage
	useEffect(() => {
		const storedUser = localStorage.getItem('user');
		if (storedUser) {
			const parsedUser = JSON.parse(storedUser);
			if (isTokenExpired(parsedUser.accessToken)) {
				refreshAccessToken(parsedUser.refreshToken);
			} else {
				setUser(parsedUser);
				setIsLoggedIn(true);
			}
		}
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			localStorage.setItem('user', JSON.stringify(user));
		} else {
			localStorage.removeItem('user');
		}
	}, [isLoggedIn, user]);

	// Modal component
	const LoginModal = () => {
		const [username, setUsername] = useState('');
		const [password, setPassword] = useState('');

		const handleLogin = async (event) => {
			event.preventDefault();
			const result = await login(username, password);
			if (result) {
				setLoginSuccess(true);
			}
		};

		const handleDone = () => {
			setShowLoginModal(false);
			setLoginSuccess(false);
		};

		const handleCancel = () => {
			setShowLoginModal(false);
		};

		return (
			<div className="loginModal">
				{loginSuccess ? (
					<div>
						<h2>Login Successful</h2>
						<button onClick={handleDone}>Done</button>
					</div>
				) : (
					<form onSubmit={handleLogin}>
						<h2>Login</h2>
						{loginError && <p className="loginError">{loginError}</p>}
						<input
							type="text"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							placeholder="Username"
							autoComplete='username'
						/>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							autoComplete='current-password'
						/>
						<button type="submit">Login</button>
						<button onClick={handleCancel}>Cancel</button>
					</form>
				)}
			</div>
		);
	};

	return {
		user,
		isLoggedIn,
		login,
		logout,
		triggerLogin,
		loginSuccess,
		LoginModal,
		showLoginModal,
	};
}
