
//Helper function to handle audio.
function useGetAudio(vid) {
    const audioFileVersions = ['audio', 'AUDIO_128'];
    const snipToReplaceWithAudio = vid.substring(vid.lastIndexOf('DASH_') + 5, vid.lastIndexOf('.mp4'));
    return audioFileVersions.map(version => vid.replace(snipToReplaceWithAudio, version));
}

export default useGetAudio;

