export const audioGenreLinks = {
    generic: {
        buttonSounds: {
            hero: "https://hierometa.com/hv3/sounds/bubbles-03-91268.mp3",
            victim: "https://hierometa.com/hv3/sounds/bubbles-108320.mp3",
            villain: "https://hierometa.com/hv3/sounds/big-bubble-2-169074.mp3",
            submit: "https://hierometa.com/hv3/sounds/cccrack-86968.mp3"
        }
    },
    dc: {
        buttonSounds: {
            hero: "https://hierometa.com/hv3/sounds/bubbles-03-91268.mp3",
            victim: "https://hierometa.com/hv3/sounds/bubbles-108320.mp3",
            villain: "https://hierometa.com/hv3/sounds/pick-92276.mp3",
            submit: "https://hierometa.com/hv3/sounds/cccrack-86968.mp3"
        }
    },
};
