import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import Home from './components/Home';
// import DetailsPage1 from './components/DetailsPage';
// // App styling for version 1 - comment out if using version 2
// import './components/App.scss';  // Importing css styles from 'App.css' file in 'src' folder


import HeroCard from './version2components/Hero/HeroCard';
import MainPage from './version2components/Main/MainPage';
import DetailsPage from './version2components/Main/DetailsPage';

import './version2components/css/themes-variables.scss';
import './version2components/css/app.scss';
import './version2components/css/buttons.scss';
import './version2components/css/main-page.scss'
import './version2components/css/details-page.scss'
import './version2components/css/actors-content.scss'
import './version2components/css/hero-card.scss'
import './version2components/css/modal-content.scss'
import 'font-awesome/css/font-awesome.min.css';


// Authentication provider
import { ProvideAuth } from './hooks/useAuth';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<MainPage />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/video/:videoId" element={<DetailsPage />} />
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/details/:videoId" element={<DetailsPage />} /> */}
        </Routes>
      </Router>
    </ProvideAuth>
  );
}

export default App;
