const CharacterRoles = {
    // Define character roles with their respective values and display texts
    HERO: { value: 1, text: 'Hero' },
    VICTIM: { value: 2, text: 'Victim' },
    VILLAIN: { value: 3, text: 'Villain' },

    /**
     * Get the name (key) of a role based on its value.
     * @param {number} value - The value of the role (e.g., 1, 2, 3).
     * @returns {string|null} - The name of the role or null if not found.
     */
    getRoleName(value) {
        return Object.keys(this).find(key => this[key].value === value);
    },

    /**
     * Get the alternative display text of a role based on its value.
     * @param {number} value - The value of the role (e.g., 1, 2, 3).
     * @returns {string|null} - The display text of the role or null if not found.
     */
    getAltText(value) {
        const role = Object.values(this).find(role => role.value === value);
        return role ? role.text : null;
    },

    /**
     * Get all role values. Can return the values as an array or an object.
     * @param {string} [format='array'] - The desired return format ('array' or 'object').
     * @returns {Array|Object} - The role values in the specified format.
     */
    getAllValues(format = 'array') {
        if (format === 'array') {
            // Return an array of objects, each containing the role name, value, and text.
            return Object.entries(this).filter(([key, role]) => role.value)
                .map(([key, role]) => ({
                    name: key,
                    value: role.value,
                    text: role.text
                }));
        } else if (format === 'object') {
            // Return an object with role names as keys and their values as values.
            const result = {};
            for (let key in this) {
                if (this[key].value) {
                    result[key] = this[key].value;
                }
            }
            return result;
        } else {
            // Throw an error if an unsupported format is provided.
            throw new Error("Unsupported format. Use 'array' or 'object'.");
        }
    }
};

export default CharacterRoles;
